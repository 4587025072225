import Truck from '@assets/icons/subscription-truck.svg';
import Coupon from '@assets/icons/coupon.svg';
import { useRecurrence } from '@hooks';

export default function SubscriptionTooltipContent() {
  const { recurrenceConfig } = useRecurrence();
  return (
    <div className="flex flex-col gap-4">
      <p className="font-bold text-primary-dark">Assinatura Mundo Danone</p>

      <div className="flex gap-2">
        <Coupon className="keep-color shrink-0" />
        <p className="font-normal text-sm text-gray-darkest">
          {recurrenceConfig.cupom_discount}% off exceto fórmulas infantis para
          lactentes e barras proteicas
        </p>
      </div>

      <div className="flex gap-2">
        <Truck className="keep-color shrink-0     " />
        <p className="font-normal text-sm text-gray-darkest">
          Frete grátis exceto para a região Norte
        </p>
      </div>

      <p className="font-normal text-sm text-gray-darkest">
        Pedido mínimo de R${recurrenceConfig.minimun_value}.
      </p>
    </div>
  );
}
