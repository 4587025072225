import { useEffect, useMemo, useRef, useState } from 'react';
import UserCircleIcon from '@assets/icons/user_circle.svg';
import ShoppingBagIcon from '@assets/icons/shopping_bag.svg';
import CalendarIcon from '@assets/icons/calendar.svg';
import UserIcon from '@assets/icons/user.svg';
import LocationPinIcon from '@assets/icons/location.svg';
import Link from 'next/link';
import { useAuth, useRecurrence } from '@hooks';
import { isEmpty } from 'lodash';

export interface ModalRef {
  open(): void;

  close(): void;
}

const OPTIONS = [
  {
    href: '/orders',
    icon: ShoppingBagIcon,
    color: 'text-white',
    label: 'Meus Pedidos'
  },
  { href: '/signature', icon: CalendarIcon, label: 'Minhas Assinaturas' },
  { href: '/account-information', icon: UserIcon, label: 'Minha Conta' },
  { href: '/addresses', icon: LocationPinIcon, label: 'Meus Endereços' }
];

const UserMenu = () => {
  const { user, handleSignOut } = useAuth();
  const wrapperRef = useRef<HTMLDivElement>(null);
  const [isUserMenuVisible, setIsUserMenuVisible] = useState(false);
  const {
    recurrenceConfig: { enabled: isRecurrenceEnabled }
  } = useRecurrence();

  const handleToggleUserMenu = () => setIsUserMenuVisible(state => !state);

  const onLogout = () => {
    handleSignOut();
    handleToggleUserMenu();
  };

  useEffect(() => {
    const handleClick = (ev: MouseEvent) => {
      if (
        wrapperRef.current &&
        !wrapperRef?.current?.contains(ev.target as Node)
      ) {
        setIsUserMenuVisible(false);
      }
    };

    if (isUserMenuVisible) {
      document?.addEventListener('mousedown', ev => {
        handleClick(ev);
      });
    }

    return () => {
      document.removeEventListener('mousedown', handleClick);
    };
  }, [isUserMenuVisible, wrapperRef]);

  /**
   * if recurrence feature is disabled
   * filters menu list removing recurrence item
   */
  const menuOptions = useMemo(() => {
    if (isRecurrenceEnabled === '0') {
      return OPTIONS.filter(option => option.href !== '/signature');
    }
    return OPTIONS;
  }, [isRecurrenceEnabled]);

  return (
    <section ref={wrapperRef} className="relative w-full">
      {!isEmpty(user) ? (
        <button
          className="cursor-pointer flex justify-between items-center max-w-[max-content] lg:ml-4"
          onClick={handleToggleUserMenu}
        >
          <UserCircleIcon
            height={22}
            width={22}
            className="flex-shrink-0 lg:mr-3 text-primary-medium lg:text-primary-medium"
          />
          <div className="hidden text-[10px] lg:flex lg:flex-col lg:items-start lg:text-auto dark:text-white">
            Olá! {user.firstname}
            <br />
            <b className="text-xs">Minha conta</b>
          </div>
        </button>
      ) : (
        <Link
          href="/sign-in"
          prefetch
          className="cursor-pointer flex justify-between items-center max-w-[max-content] lg:ml-4"
        >
          <UserCircleIcon
            height={22}
            width={22}
            className="flex-shrink-0 lg:mr-3 text-primary-medium lg:text-primary-medium"
          />
          <span className="hidden text-[10px] lg:block dark:text-white">
            Olá! Faça o <b className="text-xs">Login</b> <br /> ou{' '}
            <b className="text-xs">Cadastre-se</b>
          </span>
        </Link>
      )}

      {isUserMenuVisible && (
        <div className="absolute right-0 z-40 p-4 bg-white border shadow-md w-max lg:right-auto lg:top-10 border-gray-lightest">
          {menuOptions.map(({ href, label, color, icon }) => {
            const IconComponent = icon;

            return (
              <Link
                prefetch
                href={href}
                key={href}
                className="flex p-2 border-b border-gray-light"
                onClick={handleToggleUserMenu}
              >
                <IconComponent
                  // height={16}
                  // width={16}
                  className={`${color ? color : 'text-primary-medium'}`}
                />
                <strong className="ml-4 text-sm font-normal text-center text-gray-darkest">
                  {label}
                </strong>
              </Link>
            );
          })}

          <button
            onClick={onLogout}
            className="flex items-center w-full p-2 text-center text-sm"
          >
            Sair
          </button>
        </div>
      )}
    </section>
  );
};

export default UserMenu;
