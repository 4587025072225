import Image from 'next/image';

interface TagsProps {
  tags: {
    image: string;
    name: string;
  }[];
  narrow: boolean;
}

export const Tags = ({ tags, narrow }: TagsProps) => {
  return (
    <>
      {tags &&
        tags.map(tag => (
          <div
            key={tag.name}
            className="absolute bottom-0 right-0 w-[max-content]"
          >
            <Image
              width={narrow ? 40 : 50}
              height={narrow ? 40 : 50}
              src={tag.image}
              alt={tag.name}
            />
          </div>
        ))}
    </>
  );
};
