import { useRef } from 'react';
import { useForm } from 'react-hook-form';
import classNames from 'classnames';
import SearchIcon from '@assets/icons/search.svg';
import { useGTMDataLayer } from '@hooks';
import { useRouter } from 'next/navigation';

interface AutocompleteProps {
  containerClass?: string;
}

interface FormDataValues {
  searchValue: string;
}

export const Autocomplete = ({
  containerClass = 'hidden lg:flex w-1/3 md:mx-4'
}: AutocompleteProps) => {
  const { pushToDataLayer } = useGTMDataLayer();
  const autocompleteRef = useRef<any>(null);

  const { handleSubmit, register, watch } = useForm<FormDataValues>({
    mode: 'onChange',
    defaultValues: {
      searchValue: ''
    }
  });

  const searchInputValue = watch('searchValue') || '';

  const { push } = useRouter();
  const onSubmit = () => {
    pushToDataLayer('search', { search_term: searchInputValue });
    push(`/search?query=${searchInputValue}`);
  };

  return (
    <form
      autoComplete="off"
      className={classNames(
        'relative rounded lg:ml-11 w-full lg:mr-0 mb-2 lg:mb-0 lg:max-w-[440px]',
        containerClass
      )}
      onSubmit={handleSubmit(onSubmit)}
    >
      <div className="flex items-center px-4 lg:px-0 lg:mx-0 w-full">
        <div className="w-full" ref={autocompleteRef}>
          <input
            placeholder="Qual produto deseja encontrar?"
            className="w-full border font-normal bg-gray-lightest lg:bg-white-light border-gray-light px-4 h-[48px] rounded-l active:outline-none focus:outline-none"
            {...register('searchValue', {
              minLength: 3,
              required: true
            })}
          />
        </div>

        <button
          type="submit"
          className="bg-primary-medium disabled:hover:bg-primary-medium cursor-pointer hover:bg-primary-dark min-w-[64px] h-[48px] flex items-center justify-center rounded-r"
          disabled={searchInputValue.length < 3}
        >
          <SearchIcon
            className="text-white"
            alt="Icone de busca do menu autocomplete"
          />
        </button>
      </div>
    </form>
  );
};
