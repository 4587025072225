/* eslint-disable no-console */
import {
  ReactNode,
  createContext,
  useCallback,
  useState,
  useMemo,
  useEffect,
  memo
} from 'react';
import { first, isEmpty } from 'lodash';
import toast from 'react-hot-toast';
import { Persistence } from '@utils';

import {
  clearCartDataFromCache,
  SingleProduct,
  useStore,
  useStoreCode,
  useStoreSwitcher,
  useZipcode
} from '@hooks';
import {
  gql,
  useApolloClient,
  useLazyQuery,
  useMutation,
  useQuery
} from '@apollo/client';
import {
  AddSimpleProductToCartDocument,
  ApplyAppPromotionsDocument,
  ApplyCouponToCartDocument,
  CreateCartAfterSignInDocument,
  GetCartDetailsDocument,
  RemoveCouponFromCartDocument,
  RemoveItemForMiniCartDocument,
  SetShippingMethodDocument,
  ShippingCartPageDocument,
  UpdateItemQuantityDocument,
  useGetCustomerAddressesQuery,
  useGetCustomerCartQuery
} from '@generated/graphql';
import { useRouter, usePathname } from 'next/navigation';
import logger from 'src/services/logger';

export const GET_CART_DETAIL_ID = gql`
  query GetCartDetails($cartId: String!) {
    cart(cart_id: $cartId) {
      id
    }
  }
`;

export interface Currency {
  currency: string;
  value: number;
}

export interface iProduct {
  id: number;
  sku: string;
  quantity: number;
  product: SingleProduct;
  errors: { message: string }[];
  configurable_options: {
    id: number;
    option_label: string;
    value_id: number;
    value_label: string;
  }[];
}
export interface iAddProduct {
  product: iProduct;
  quantity?: number;
  onCompleted: (data: any) => void;
  onError?: (error: any) => void;
}
export interface iUpdateProduct {
  quantity?: number;
  id?: boolean;
}
export interface iCart {
  id: string;
  items: iProduct[];
  is_recurrence: '0' | '1';
  prices: {
    subtotal_excluding_tax: Currency;
    applied_taxes: {
      amount: Currency;
      value: number;
    };
    discounts: {
      amount: Currency;
      label: string;
    }[];
    grand_total: Currency;
  };
  shipping_addresses: {
    customer_address_id: number;
    firstname: string;
    lastname: string;
    street: string[];
    city: string;
    region: {
      code: string;
      label: string;
      region_id: string;
    };
    country: {
      code: string;
      label: string;
    };
    postcode: string;
    company: string;
    telephone: string;
    available_shipping_methods: {
      description: string;
      amount: Currency;
      available: string;
      carrier_code: string;
      carrier_title: string;
      error_message: string;
      method_code: string;
      method_title: string;
      price_excl_tax: Currency;
      price_incl_tax: Currency;
    }[];
    selected_shipping_method: {
      amount: Currency;
      carrier_code: string;
      carrier_title: string;
      method_code: string;
      method_title: string;
    };
  }[];
  applied_gift_cards: {
    code: number;
    applied_balance: Currency;
  };
  applied_coupons: {
    code: string;
    description: string;
  }[];
  total_quantity: number;
}
export interface iCartContext {
  cartId: string | null;
  cartToken(): Promise<string>;
  cart: iCart | undefined;
  isSignature: boolean;
  cartItemsQuantity: number;
  fetchCart(params?: any): Promise<any>;
  createCart(): Promise<any>;
  removeCart: () => Promise<void>;
  handleAddProductToCart(iAddProduct): Promise<void>;
  handleRemoveProductFromCart(string): Promise<void>;
  handleUpdateCartItemQuantity(iUpdateProduct): Promise<any>;
  handleAddCouponToCart(couponCode: string): void;
  handleRemoveCouponFromCart(): void;
  loading: boolean;
  productPageAvailabilityError: boolean;
  setProductPageAvailabilityError(boolean): void;
  userAddressList: any;
  userAddressLoading: boolean;
  refetchUserAddresses(): Promise<any>;
  handleLoadShippingMethods(payload: { newCartId?: string }): Promise<any>;
  setShippingMethod(payload: {
    variables: {
      cartId: string;
      shippingMethod: {
        carrier_code: string;
        method_code: string;
      };
    };
  }): Promise<any>;
}

export const CartContext = createContext<iCartContext>({} as iCartContext);

export const CartProvider = memo(({ children }: { children: ReactNode }) => {
  const [createCartMutation] = useMutation(CreateCartAfterSignInDocument, {
    fetchPolicy: 'no-cache'
  });
  const [updateItemOnCart] = useMutation(UpdateItemQuantityDocument, {
    fetchPolicy: 'no-cache'
  });
  const [applyAppPromotions] = useMutation(ApplyAppPromotionsDocument, {
    fetchPolicy: 'no-cache'
  });
  const [setCouponToCart] = useMutation(ApplyCouponToCartDocument, {
    fetchPolicy: 'no-cache'
  });
  const [addProductToCart] = useMutation(AddSimpleProductToCartDocument, {
    fetchPolicy: 'no-cache'
  });
  const [removeItemFromCart] = useMutation(RemoveItemForMiniCartDocument, {
    fetchPolicy: 'no-cache'
  });
  const [setShippingMethod] = useMutation(SetShippingMethodDocument, {
    fetchPolicy: 'no-cache'
  });
  const [deleteCouponFromCart] = useMutation(RemoveCouponFromCartDocument, {
    fetchPolicy: 'no-cache'
  });
  const [setDefaultShippingMethod] = useMutation(ShippingCartPageDocument, {
    fetchPolicy: 'no-cache'
  });

  let cartId = Persistence.getItem('@cart/id') as string;
  const token = Persistence.getItem('signin_token') as string;

  const { refetch: getCartDetails } = useQuery(GET_CART_DETAIL_ID, {
    variables: { cartId: cartId },
    skip: !cartId
  });

  const getCurrentCartId = useCallback(async () => {
    if (cartId) {
      const { data } = await getCartDetails();
      return data?.cart?.id;
    }
    throw new Error('@cart/id should not be empty while fetching cart');
  }, [cartId, getCartDetails]);

  const apolloClient = useApolloClient();
  const pathname = usePathname();
  const { zipcode } = useZipcode();
  const { availableStores } = useStore();
  const { handleSetStore } = useStoreCode();
  const { handleSwitchStoreByPostcode } = useStoreSwitcher();
  const [productPageAvailabilityError, setProductPageAvailabilityError] =
    useState<boolean>(false);

  const {
    data: userAddressData,
    loading: userAddressLoading,
    refetch: refetchUserAddresses
  } = useGetCustomerAddressesQuery({ skip: !cartId || !token });

  const router = useRouter();
  const isSignedIn = Persistence.getItem('signin_token');
  const { error: customerCartError } = useGetCustomerCartQuery({
    skip: !isSignedIn
  });

  const userAddressList = useMemo(() => {
    if (userAddressData?.customer.addresses) {
      const defaultShipmentAddress = userAddressData.customer.addresses.find(
        ({ default_shipping }) => default_shipping === true
      );

      if (defaultShipmentAddress)
        return [
          defaultShipmentAddress,
          ...userAddressData.customer.addresses.filter(
            ({ default_shipping }) => default_shipping !== true
          )
        ];
    }

    return userAddressData?.customer.addresses;
  }, [userAddressData]);

  const [fetchCart, { data: cartData, loading }] = useLazyQuery(
    GetCartDetailsDocument,
    {
      variables: { cartId },
      fetchPolicy: 'no-cache',
      errorPolicy: 'all'
    }
  );

  const createCart = useCallback(async () => {
    try {
      const {
        data: { cartId: createdCartId }
      } = await createCartMutation();

      if (createdCartId) {
        Persistence.setItem('@cart/id', createdCartId);

        return createdCartId;
      } else {
        throw new Error('Um erro aconteceu tentando criar seu carrinho novo');
      }
    } catch (err) {
      toast.error(err.message);
    }
  }, [createCartMutation]);

  const cartToken = useCallback(async () => {
    let token = '';
    try {
      token = await getCurrentCartId();
    } catch (error) {
      token = await createCart();
    }
    await Persistence.setItem('@cart/id', token);
    return token;
  }, [createCart, getCurrentCartId]);

  const errorToAddProductToCart = useCallback(
    async ({ error, callback }) => {
      const fallbackStoreCode = Persistence.getItem(
        'store_vendor_fallback_code'
      );

      if (fallbackStoreCode) {
        const usedFallback = await handleSwitchStoreByPostcode(
          zipcode,
          true,
          callback
        );

        if (usedFallback) {
          setProductPageAvailabilityError(false);
        }
      } else {
        setProductPageAvailabilityError(true);

        if (!pathname.includes('/p')) toast.error(error?.message);
      }
    },
    [handleSwitchStoreByPostcode, pathname, zipcode]
  );

  const handleAppPromotions = useCallback(async () => {
    try {
      await applyAppPromotions({
        variables: {
          cartId,
          value: '0'
        }
      });
    } catch (e) {
      logger.error(e);
    }
  }, [applyAppPromotions, cartId]);

  useEffect(() => {
    if (cartId) {
      handleAppPromotions();
    }
  }, [cartId, handleAppPromotions]);

  const handleAddProductToCart = useCallback(
    async ({ quantity = 1, product, onCompleted, onError }: iAddProduct) => {
      const cartIdCreated = await cartToken();

      if (cartIdCreated) {
        const fallbackStoreCode = Persistence.getItem(
          'store_vendor_fallback_code'
        );

        const { errors: errorsSimple } = await addProductToCart({
          variables: {
            cartId: cartIdCreated,
            quantity: quantity,
            sku: product?.sku
          },
          errorPolicy: 'all',
          onCompleted: data =>
            onCompleted({ data, createdCartId: cartIdCreated }) as any
        });

        if (errorsSimple && errorsSimple.length > 0) {
          await errorToAddProductToCart({
            error: errorsSimple[0],
            callback: async () => {
              if (fallbackStoreCode) {
                const { errors: errorsFallback } = await addProductToCart({
                  variables: {
                    cartId: cartIdCreated,
                    quantity: quantity,
                    sku: product?.sku
                  },
                  errorPolicy: 'all',
                  context: { headers: { store: fallbackStoreCode } },
                  onCompleted: data =>
                    onCompleted({ data, createdCartId: cartIdCreated }) as any
                });
                if (errorsFallback && errorsFallback.length > 0) {
                  setProductPageAvailabilityError(true);
                  if (onError) {
                    onError(errorsFallback[0]);
                  }
                }
              }
            }
          });
        }
      }
    },
    [addProductToCart, cartToken, errorToAddProductToCart]
  );

  const handleRemoveProductFromCart = useCallback(
    async (itemId: number) => {
      const cartIdCreated = await cartToken();

      await removeItemFromCart({
        variables: {
          cartId: cartIdCreated,
          itemId
        },
        errorPolicy: 'all'
      });

      await fetchCart();
    },
    [cartToken, fetchCart, removeItemFromCart]
  );

  const handleUpdateCartItemQuantity = useCallback(
    async ({ id, quantity }: iUpdateProduct) => {
      const cartIdCreated = await cartToken();
      if (cartIdCreated) {
        const fallbackStoreCode = Persistence.getItem(
          'store_vendor_fallback_code'
        );

        const { errors } = await updateItemOnCart({
          variables: {
            cartId: cartIdCreated,
            itemId: id,
            quantity
          },
          errorPolicy: 'all',
          onCompleted: async () => await fetchCart()
        });

        if (errors && errors.length > 0) {
          await errorToAddProductToCart({
            error: errors[0],
            callback: async () =>
              await updateItemOnCart({
                variables: {
                  cartId: cartIdCreated,
                  itemId: id,
                  quantity
                },
                context: { headers: { store: fallbackStoreCode } },
                onCompleted: async () => await fetchCart()
              })
          });
        }
      }
    },
    [cartToken, errorToAddProductToCart, fetchCart, updateItemOnCart]
  );

  const handleAddCouponToCart = useCallback(
    async (couponCode: string) => {
      const cartIdCreated = await cartToken();
      const { errors } = await setCouponToCart({
        variables: {
          cartId: cartIdCreated,
          couponCode
        },
        errorPolicy: 'all'
      });
      if (errors) {
        const err = errors.find(
          err =>
            err?.message !== 'A quantidade selecionada não está disponível' &&
            err?.message !== 'The requested qty is not available' &&
            err?.message !== 'Alguns dos produtos estão fora de estoque.'
        );
        if (err) toast.error(err?.message);
      }
      await fetchCart();
    },
    [setCouponToCart, fetchCart, cartToken]
  );

  const handleRemoveCouponFromCart = useCallback(async () => {
    const cartIdCreated = await cartToken();
    const { errors } = await deleteCouponFromCart({
      variables: {
        cartId: cartIdCreated
      },
      errorPolicy: 'all'
    });
    if (errors) {
      const err = errors.find(
        err =>
          err?.message !== 'A quantidade selecionada não está disponível' &&
          err?.message !== 'The requested qty is not available' &&
          err?.message !== 'Alguns dos produtos estão fora de estoque.'
      );
      if (err) toast.error(err?.message);
    }
    await fetchCart();
  }, [deleteCouponFromCart, cartToken, fetchCart]);

  const handleLoadShippingMethods = useCallback(
    async ({ newCartId }: { newCartId: string }) => {
      if (zipcode) {
        await setDefaultShippingMethod({
          variables: {
            cartId: newCartId,
            postCode: zipcode
          },
          onCompleted: async () => {
            const { data: defaultShippingData } = await fetchCart();

            const shippingAddressOnCart = first(
              defaultShippingData?.cart?.shipping_addresses
            ) as any;

            const availableShippingMethod = first(
              shippingAddressOnCart?.available_shipping_methods
            ) as any;

            if (!isEmpty(availableShippingMethod)) {
              await setShippingMethod({
                variables: {
                  cartId: newCartId,
                  shippingMethod: {
                    carrier_code: availableShippingMethod?.carrier_code,
                    method_code: availableShippingMethod?.method_code
                  }
                }
              });
              await fetchCart();
            }
          }
        });
      }
    },
    [fetchCart, setDefaultShippingMethod, setShippingMethod, zipcode]
  );

  const removeCart = useCallback(async () => {
    Persistence.removeItem('@cart/id');
    await clearCartDataFromCache(apolloClient);
  }, [apolloClient]);

  const cart = useMemo(() => {
    if (cartData?.cart) {
      const cartWithoutNullItems = {
        ...cartData?.cart,
        items: cartData?.cart?.items?.filter(item => item)
      };
      return cartWithoutNullItems as iCart;
    }

    return {} as iCart;
  }, [cartData]);

  const isSignature = useMemo(() => {
    return cart?.is_recurrence === '1';
  }, [cart?.is_recurrence]);

  const cartItemsQuantity = useMemo(() => {
    return cart?.items?.reduce((prev, current) => {
      return current?.quantity + prev;
    }, 0);
  }, [cart]);

  const foundErrorInCart = useMemo(() => {
    const productWithError = cart?.items?.find(
      product => product?.errors?.length > 0 && product
    );

    if (productWithError?.errors) {
      const [cartError] = productWithError.errors;

      return cartError;
    }

    return null;
  }, [cart?.items]);

  useEffect(() => {
    if (cartId) {
      fetchCart();
    }
  }, [cartId, fetchCart]);

  useEffect(() => {
    const handleError = async err => {
      const fallbackStoreCode = Persistence.getItem(
        'store_vendor_fallback_code'
      );

      if (
        (err?.message === 'A quantidade selecionada não está disponível' ||
          err?.message === 'The requested qty is not available' ||
          err?.message === 'Alguns dos produtos estão fora de estoque.') &&
        fallbackStoreCode
      ) {
        let fallbackStore;
        availableStores.forEach(store => {
          if (store.code === fallbackStoreCode) fallbackStore = store;
        });

        if (!isEmpty(fallbackStore)) {
          handleSetStore(fallbackStore?.code);
          Persistence.setItem('store_view_currency', fallbackStore.currency);
          Persistence.setItem(
            'store_view_secure_base_media_url',
            fallbackStore.secure_base_media_url
          );

          Persistence.removeItem('store_vendor_fallback_code');
          await fetchCart();
        }
      }
    };

    if (foundErrorInCart) handleError(foundErrorInCart);
  }, [availableStores, foundErrorInCart, fetchCart, handleSetStore]);

  /**
   * When useCart is called, if it gets any authorization error
   * clear cart and local storage and redirects to sign-in
   */
  useEffect(() => {
    const cleanAndRedirectToSignIn = async () => {
      await removeCart();

      localStorage.clear();
      router.push(`/sign-in?redirect=${pathname}`);
    };

    if (
      isSignedIn &&
      customerCartError?.graphQLErrors?.[0]?.extensions?.category ===
        'graphql-authorization'
    ) {
      cleanAndRedirectToSignIn();
    }
  }, [
    customerCartError?.graphQLErrors,
    pathname,
    isSignedIn,
    removeCart,
    router
  ]);

  return (
    <CartContext.Provider
      value={{
        cart,
        cartId,
        cartToken,
        cartItemsQuantity,
        handleAddProductToCart,
        handleRemoveProductFromCart,
        handleUpdateCartItemQuantity,
        handleAddCouponToCart,
        handleRemoveCouponFromCart,
        createCart,
        removeCart,
        loading,
        fetchCart,
        productPageAvailabilityError,
        setProductPageAvailabilityError,
        userAddressList,
        userAddressLoading,
        refetchUserAddresses,
        setShippingMethod,
        handleLoadShippingMethods,
        isSignature
      }}
    >
      {children}
    </CartContext.Provider>
  );
});

CartProvider.displayName = 'CartProvider';
