import { gql, useMutation, useQuery } from '@apollo/client';
import {
  AddConfigurableProductsToCartDocument,
  AddSimpleProductToCartDocument
} from '@generated/graphql';
import { find, first, groupBy, has, isEmpty, keys } from 'lodash';
import { useCallback, useMemo, useState } from 'react';
import toast from 'react-hot-toast';
import { useCart } from './useCart';
import { useGTMDataLayer } from './useGTMDataLayer';

const INITIAL_OPTION_CODES = new Map();
const INITIAL_OPTION_SELECTIONS = new Map();

export const appendOptionsToPayload = (
  payload,
  optionSelections,
  optionCodes
) => {
  const { item } = payload;
  const { variants } = item;

  if (!optionCodes) {
    optionCodes = new Map();
    for (const option of item.configurable_options) {
      // There's a type difference in configurable option queries between
      // cart and product, casting to number is required. Can remove
      // cast once MC-29839 is resolved.
      optionCodes.set(Number(option.attribute_id), option.attribute_code);
    }
  }

  const options = Array.from(optionSelections, ([id, value]) => ({
    option_id: id,
    option_value: value
  }));

  const selectedVariant = findMatchingVariant({
    variants,
    optionCodes,
    optionSelections
  });

  if (!selectedVariant) return payload;

  Object.assign(payload, {
    options,
    parentSku: item.sku,
    item: Object.assign({}, selectedVariant.product)
  });

  return payload;
};

const deriveOptionCodesFromProduct = product => {
  // If this is a simple product it has no option codes.
  if (product && !isProductConfigurable(product)) {
    return INITIAL_OPTION_CODES;
  }

  // Initialize optionCodes based on the options of the product.
  if (product && !product?.configurable_options) {
    return INITIAL_OPTION_CODES;
  }

  const initialOptionCodes = new Map();
  if (product && product?.configurable_options) {
    for (const {
      attribute_id,
      attribute_code
    } of product.configurable_options) {
      initialOptionCodes.set(attribute_id, attribute_code);
    }

    return initialOptionCodes;
  }

  return INITIAL_OPTION_CODES;
};

// Similar to deriving the initial codes for each option.
const deriveOptionSelectionsFromProduct = product => {
  if (product && !isProductConfigurable(product)) {
    return INITIAL_OPTION_SELECTIONS;
  }

  if (product && product?.configurable_options) {
    const initialOptionSelections = new Map();
    const currentProduct = product?.variants?.find(
      variant => variant?.product?.url_key === product.product_url_key
    );
    const currentFlavor = currentProduct?.product?.custom_flavor;
    const initialOption =
      currentFlavor ||
      product?.configurable_options?.[0]?.values?.[0]?.value_index;

    for (const { attribute_id } of product.configurable_options) {
      initialOptionSelections.set(attribute_id, initialOption);
    }

    return initialOptionSelections;
  }

  return INITIAL_OPTION_CODES;
};

const getConfigName = (product, optionCodes, optionSelections) => {
  if (!product) {
    return '';
  }

  let name;

  const isConfigurable = product?.__typename && isProductConfigurable(product);

  const optionsSelected =
    Array.from(optionSelections.values()).filter(value => !!value).length > 0;

  if (!isConfigurable || !optionsSelected) {
    name = product.name;
  } else {
    const item = findMatchingVariant({
      optionCodes,
      optionSelections,
      variants: product.variants
    });

    name = item ? item.product.name : product.name;
  }

  return name;
};

const getIsMissingOptions = (product, optionSelections) => {
  // Non-configurable products can't be missing options.
  if (product && product?.__typename && !isProductConfigurable(product)) {
    return false;
  }

  // Configurable products are missing options if we have fewer
  // option selections than the product has options.
  const numProductOptions = product && product?.configurable_options.length;
  const numProductSelections = Array.from(optionSelections.values()).filter(
    value => !!value
  ).length;

  return numProductSelections < (numProductOptions || 0);
};

const findMatchingVariant = ({ variants, optionCodes, optionSelections }) => {
  return variants.find(({ attributes, product }) => {
    const customAttributes = (attributes || []).reduce(
      (map, { code, value_index }) => new Map(map).set(code, value_index),
      new Map()
    );

    for (const [id, value] of optionSelections) {
      const code = optionCodes.get(id);
      const matchesStandardAttribute = product[code] === value;
      const matchesCustomAttribute = customAttributes.get(code) === value;

      // if any option selection fails to match any standard attribute
      // and also fails to match any custom attribute
      // then this isn't the correct variant
      if (!matchesStandardAttribute && !matchesCustomAttribute) {
        return false;
      }
    }

    // otherwise, every option selection matched
    // and this is the correct variant
    return true;
  });
};

export const isProductConfigurable = product =>
  product?.__typename === 'ConfigurableProduct';

const getConfigPrice = (product, optionCodes, optionSelections) => {
  if (!product) {
    return 0;
  }

  let price;

  const isConfigurable =
    product && product?.__typename && isProductConfigurable(product);

  const optionsSelected =
    Array.from(optionSelections.values()).filter(value => !!value).length > 0;

  if (!isConfigurable || !optionsSelected) {
    price = (product?.price_range && product?.price_range?.minimum_price) || 0;
  } else {
    const item = findMatchingVariant({
      optionCodes,
      optionSelections,
      variants: product && product?.variants
    });

    price = item
      ? item?.product?.price_range?.minimum_price
      : product?.price_range?.minimum_price;
  }

  return price;
};

const getMediaGalleryEntries = (product, optionCodes, optionSelections) => {
  if (!product) {
    return;
  }

  let value = [];

  const isConfigurable =
    product && product?.__typename && isProductConfigurable(product);

  // Selections are initialized to "code => undefined". Once we select a value, like color, the selections change. This filters out unselected options.
  const optionsSelected =
    Array.from(optionSelections.values()).filter(value => !!value).length > 0;

  if (product && (!isConfigurable || !optionsSelected)) {
    value = product?.media_gallery_entries;
  } else {
    // If any of the possible variants matches the selection add that
    // variant's image to the media gallery. NOTE: This _can_, and does,
    // include variants such as size. If Magento is configured to display
    // an image for a size attribute, it will render that image.
    const item = findMatchingVariant({
      optionCodes,
      optionSelections,
      variants: product && product?.variants
    });

    value = item
      ? [...item.product.media_gallery_entries]
      : product?.media_gallery_entries;
  }

  return value;
};

export const getBreadcrumbCategory = categories => {
  // Exit if there are no categories for this product.
  if (!categories || !categories.length) {
    return;
  }
  const breadcrumbSet = new Set();
  categories.forEach(({ breadcrumbs }) => {
    // breadcrumbs can be `null`...
    (breadcrumbs || []).forEach(({ category_id }) =>
      breadcrumbSet.add(category_id)
    );
  });

  // Until we can get the single canonical breadcrumb path to a product we
  // will just return the first category id of the potential leaf categories.
  const leafCategory = categories.find(
    category => !breadcrumbSet.has(category.id)
  );

  // If we couldn't find a leaf category then just use the first category
  // in the list for this product.
  return leafCategory || categories[0];
};

const getConfigProps = (product, optionCodes, optionSelections) => {
  if (!product) {
    return null;
  }

  let props: Record<string, unknown> = {
    custom_urgency: product?.custom_urgency,
    short_description: product?.short_description,
    description: product?.description,
    custom_preparation_mode: product?.custom_preparation_mode,
    custom_use_care: product?.custom_use_care,
    custom_ingredients: product?.custom_ingredients,
    custom_nutritional_table: product?.custom_nutritional_table,
    custom_brand: product?.custom_brand,
    custom_manufacturer: product?.custom_manufacturer,
    custom_packing: product?.custom_packing,
    custom_format: product?.custom_format,
    custom_weight: product?.custom_weight,
    custom_flavor: product?.custom_flavor,
    custom_dimensions: product?.custom_dimensions,
    stock_status: product?.stock_status,
    is_configurable: false,
    has_matching_variant: false,
    sku: product?.sku,
    parent_sku: null,
    max_sale_qty: product?.max_sale_qty,
    is_discount_recurrence: product?.is_discount_recurrence
  };

  const { variants } = product;

  const isConfigurable =
    product && product?.__typename && isProductConfigurable(product);

  props.is_configurable = isConfigurable;

  const optionsSelected =
    Array.from(optionSelections.values()).filter(value => !!value).length > 0;

  if (isConfigurable && optionsSelected) {
    const item = findMatchingVariant({
      optionCodes,
      optionSelections,
      variants
    });

    if (item) {
      props = {
        has_matching_variant: true,
        custom_urgency:
          item?.product?.custom_urgency || product?.custom_urgency,
        short_description:
          item?.product?.short_description || product?.short_description,
        description: item?.product?.description || product?.description,
        custom_preparation_mode:
          item?.product?.custom_preparation_mode ||
          product?.custom_preparation_mode,
        custom_use_care:
          item?.product?.custom_use_care || product?.custom_use_care,
        custom_ingredients:
          item?.product?.custom_ingredients || product?.custom_ingredients,
        custom_nutritional_table:
          item?.product?.custom_nutritional_table ||
          product?.custom_nutritional_table,
        custom_brand: item?.product?.custom_brand || product?.custom_brand,
        custom_manufacturer:
          item?.product?.custom_manufacturer || product?.custom_manufacturer,
        custom_packing:
          item?.product?.custom_packing || product?.custom_packing,
        custom_format: item?.product?.custom_format || product?.custom_format,
        custom_weight: item?.product?.custom_weight || product?.custom_weight,
        custom_flavor: item?.product?.custom_flavor || product?.custom_flavor,
        custom_dimensions:
          item?.product?.custom_dimensions || product?.custom_dimensions,
        stock_status: item?.product?.stock_status || product?.stock_status,
        sku: item?.product?.sku || product?.sku,
        parent_sku: product?.sku,
        max_sale_qty: item?.product?.max_sale_qty,
        is_discount_recurrence:
          item?.product?.is_discount_recurrence ||
          product?.is_discount_recurrence
      };
    }
  }

  return props;
};

export const GET_CUSTOM_ATTRIBUTE_METADATA = gql`
  query getCustomAtributeMetadata {
    customAttributeMetadata(
      attributes: [
        { attribute_code: "custom_flavor", entity_type: "catalog_product" }
        { attribute_code: "custom_dimensions", entity_type: "catalog_product" }
        { attribute_code: "custom_packing", entity_type: "catalog_product" }
        {
          attribute_code: "custom_manufacturer"
          entity_type: "catalog_product"
        }
        { attribute_code: "custom_format", entity_type: "catalog_product" }
        { attribute_code: "custom_brand", entity_type: "catalog_product" }
        { attribute_code: "custom_weight", entity_type: "catalog_product" }
      ]
    ) {
      items {
        attribute_code
        attribute_type
        entity_type
        input_type
        attribute_options {
          value
          label
        }
      }
    }
  }
`;

export const CartTriggerFragment = gql`
  fragment CartTriggerFragment on Cart {
    id
    total_quantity
  }
`;

const SUPPORTED_PRODUCT_TYPES = ['SimpleProduct', 'ConfigurableProduct'];

export interface useCustomAttributesProps {
  skip?: boolean;
}

export const useCustomAttributes = ({
  skip
}: useCustomAttributesProps = {}) => {
  const { loading, data } = useQuery(GET_CUSTOM_ATTRIBUTE_METADATA, { skip });

  const metadata = useMemo(() => {
    if (!loading && !isEmpty(data)) {
      const grouped = groupBy(
        data?.customAttributeMetadata?.items,
        'attribute_code'
      );

      keys(grouped).map(key => {
        return (grouped[key] = first(grouped[key]));
      });

      return grouped || [];
    }
  }, [data, loading]);

  const getProductCustomAttributes = useCallback(
    product => {
      const attributes = {};

      if (loading || typeof metadata === 'undefined') return attributes;

      for (const key of keys(metadata)) {
        const attr: Record<string, any> = metadata[key];

        if (has(product, key) && attr?.input_type === 'select') {
          const option = find(attr?.attribute_options, {
            value: String(product[key])
          });

          if (!isEmpty(option)) {
            attributes[key] = option?.label;
          }
        }
      }

      return {
        ...attributes,
        custom_attrs_loaded: true
      };
    },
    [loading, metadata]
  );

  return {
    getProductCustomAttributes,
    loading,
    metadata
  };
};

export const useProductFullDetail = ({ product }) => {
  const derivedOptionSelections = useMemo(
    () => deriveOptionSelectionsFromProduct(product),
    [product]
  );

  const [optionSelections, setOptionSelections] = useState(
    derivedOptionSelections
  );

  const isMissingOptions = useMemo(
    () => getIsMissingOptions(product, optionSelections),
    [product, optionSelections]
  );

  const handleSelectionChange = useCallback(
    (optionId, selection) => {
      // We must create a new Map here so that React knows that the value
      // of optionSelections has changed.
      const nextOptionSelections = new Map([...optionSelections]);
      nextOptionSelections.set(optionId, selection);
      setOptionSelections(nextOptionSelections);
    },
    [optionSelections]
  );

  const derivedOptionCodes = useMemo(
    () => deriveOptionCodesFromProduct(product),
    [product]
  );
  const [optionCodes] = useState(derivedOptionCodes);

  const mediaGalleryEntries = useMemo(
    () => getMediaGalleryEntries(product, optionCodes, optionSelections),
    [product, optionCodes, optionSelections]
  );

  const productPrice = useMemo(
    () => getConfigPrice(product, optionCodes, optionSelections),
    [product, optionCodes, optionSelections]
  );

  const productName = useMemo(
    () => getConfigName(product, optionCodes, optionSelections),
    [product, optionCodes, optionSelections]
  );

  let price_tiers;
  if (product) {
    if (product?.__typename && isProductConfigurable(product)) {
      const variant = findMatchingVariant({
        variants: product.variants,
        optionCodes,
        optionSelections
      });

      price_tiers = variant ? variant.product.price_tiers : [];
    } else {
      price_tiers = product.price_tiers;
    }
  }

  const productProps = useMemo(
    () => getConfigProps(product, optionCodes, optionSelections),
    [product, optionCodes, optionSelections]
  );

  const productType = product && product?.__typename;

  const isSupportedProductType = SUPPORTED_PRODUCT_TYPES.includes(productType);

  const breadcrumbCategory = useMemo(
    () => product && getBreadcrumbCategory(product.categories),
    [product]
  );

  const [
    addSimpleProductToCart
    // {
    //   error: errorAddingSimpleProduct,
    //   loading: isAddSimpleLoading,
    //   data: dataAddingSimpleProduct
    // }
  ] = useMutation(AddSimpleProductToCartDocument);

  const [
    addConfigurableProductToCart
    // {
    //   error: errorAddingConfigurableProduct,
    //   loading: isAddConfigurableLoading,
    //   data: dataAddingConfigurableProduct
    // }
  ] = useMutation(AddConfigurableProductsToCartDocument);

  const { cart, cartToken } = useCart();

  const { pushToDataLayer } = useGTMDataLayer();

  const { getProductCustomAttributes } = useCustomAttributes();

  const productDetails = useMemo(
    () => ({
      variantName: productName,
      price: productPrice,
      price_tiers,
      ...productProps,
      ...getProductCustomAttributes(productProps)
    }),
    [
      getProductCustomAttributes,
      price_tiers,
      productName,
      productPrice,
      productProps
    ]
  );

  const handleAddToCart = useCallback(
    async formValues => {
      const cartCreated = await cartToken();
      if (!isSupportedProductType) {
        const message = 'Unsupported product type. Cannot add to cart.';
        // eslint-disable-next-line no-console
        console.error(message);

        return;
      }

      const { quantity } = formValues;

      const payload: Record<string, any> = {
        item: product,
        productType,
        quantity
      };

      if (product && product?.__typename && isProductConfigurable(product)) {
        appendOptionsToPayload(payload, optionSelections, optionCodes);
      }

      const variables = {
        cartId: cartCreated,
        parentSku: payload.parentSku,
        quantity: payload.quantity,
        sku: payload.item.sku
      };

      // Use the proper mutation for the type.
      if (productType === 'SimpleProduct') {
        const response = await addSimpleProductToCart({
          variables,
          errorPolicy: 'all'
        });

        if (has(response, 'errors')) {
          // eslint-disable-next-line no-console
          console.error(response.errors);
        } else {
          toast.success('Produto adicionado com sucesso!');

          pushToDataLayer('addToCart', {
            ...productDetails,
            category: breadcrumbCategory.name,
            quantity,
            value: quantity * productDetails?.price?.final_price?.value,
            coupon: cart?.applied_coupons?.[0]?.code,
            discount: cart?.prices?.discounts?.[0]?.amount?.value
          });
        }

        return response;
      }

      if (productType === 'ConfigurableProduct') {
        const response = await addConfigurableProductToCart({
          variables,
          errorPolicy: 'all'
        });

        if (has(response, 'errors')) {
          // eslint-disable-next-line no-console
          console.error(response.errors);
        } else {
          toast.success('Produto adicionado com sucesso!');

          pushToDataLayer('addToCart', {
            ...productDetails,
            category: breadcrumbCategory.name,
            quantity,
            value: quantity * productDetails?.price?.final_price?.value,
            coupon: cart?.applied_coupons?.[0]?.code,
            discount: cart?.prices?.discounts?.[0]?.amount?.value
          });
        }

        return response;
      }
    },
    [
      cartToken,
      isSupportedProductType,
      product,
      productType,
      optionSelections,
      optionCodes,
      addSimpleProductToCart,
      pushToDataLayer,
      productDetails,
      breadcrumbCategory.name,
      cart?.applied_coupons,
      cart?.prices?.discounts,
      addConfigurableProductToCart
    ]
  );

  return {
    mediaGalleryEntries,
    productDetails,
    handleSelectionChange,
    optionSelections,
    handleAddToCart,
    isMissingOptions
  };
};
