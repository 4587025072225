import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import { Button, Modal } from '@common';
import { ProductList } from './ProductList';
import { useMiniCart } from '../../../hooks/useMiniCart';
import { useStockStatusMessage } from '@utils';
import toast from 'react-hot-toast';
import CloseIcon from '@assets/icons/close.svg';
import ShoppingCartIcon from '@assets/icons/cart.svg';
import styles from './miniCart.module.scss';
import { useCart, useRecurrence } from '@hooks';
import { FreeShippingProgressBar } from '../FreeShippingProgressBar';
import SubscriptionMinibanner from '../TabBar/SubscriptionMinibanner';

const StockStatusMessage = ({
  cartItems,
  messageId = 'stockStatusMessage.message',
  message = 'An item in your cart is currently out-of-stock and must be removed in order to Checkout.',
  className = ''
}) => {
  const talonProps = useStockStatusMessage({ cartItems });
  const { hasOutOfStockItem } = talonProps;

  const stockStatusMessageElement = hasOutOfStockItem ? (
    <div
      className={`bg-error-lightest text-error-dark border-none rounded p-3.5 flex flex-row items-start w-full ${className}`}
    >
      <CloseIcon size={24} className="mr-3.5 text-error-medium min-w-[22px]" />
      <div className="flex flex-col text-sm">
        <strong className="text-error-medium text-base">
          Indisponível para sua localização
        </strong>
        <FormattedMessage id={messageId} defaultMessage={message} />
      </div>
    </div>
  ) : null;

  return stockStatusMessageElement;
};

export const MiniCart = ({ isOpen, setIsOpen }) => {
  const {
    loading,
    subTotal,
    errorMessage,
    closeMiniCart,
    handleEditCart,
    productList,
    configurableThumbnailSource
  } = useMiniCart(setIsOpen);
  const {
    recurrenceConfig: { enabled }
  } = useRecurrence();
  const { isSignature, cartItemsQuantity, handleRemoveProductFromCart } =
    useCart();

  const { hasOutOfStockItem } = useStockStatusMessage({
    cartItems: productList
  });

  const isCartEmpty = !(productList && productList.length);

  useEffect(() => {
    if (errorMessage) {
      toast.error(errorMessage);
    }
  }, [errorMessage]);

  return (
    <Modal
      className="h-full flex flex-col bg-white lg:w-[356px] lg:ml-auto shadow-lg"
      overlayClassName="!bg-transparent !cursor-default"
      wrapperClassName="h-full"
      handleToggle={() => setIsOpen(prevState => !prevState)}
      isVisible={isOpen}
    >
      <div className={styles.header}>
        <div className="flex flex-row justify-end">
          <button type="button" onClick={closeMiniCart}>
            <CloseIcon width={28} className="stroke-current text-white" />
          </button>
        </div>
      </div>

      {isCartEmpty ? (
        <div className="flex flex-col h-[calc(100%-70px)]">
          <div className="flex-1 p-0 flex flex-col justify-center items-center">
            <ShoppingCartIcon width={64} className="text-primary-medium" />

            <p className="text-center pt-4 max-w-38">
              <strong className="text-2xl text-gray-medium">
                <FormattedMessage
                  id={'miniCart.emptyMessage'}
                  defaultMessage={'There are no items in your cart.'}
                />
              </strong>
            </p>
          </div>

          <div className="px-3 py-6">
            <button
              type="button"
              className="bg-primary-medium p-4 rounded uppercase font-bold text-sm text-white w-full"
              onClick={closeMiniCart}
            >
              Voltar as compras
            </button>
          </div>
        </div>
      ) : (
        <div className="h-[calc(100%-290px)]">
          <div className={styles.body}>
            {hasOutOfStockItem && (
              <div className={styles.stockStatusMessageContainer}>
                <StockStatusMessage
                  cartItems={productList}
                  className="md:mx-0"
                />
              </div>
            )}
            <div className="px-2 mt-4">
              <FreeShippingProgressBar />
            </div>
            {isSignature && enabled != '0' && (
              <div className="px-3">
                <SubscriptionMinibanner isCheckout />
              </div>
            )}
            <div className="mt-4 px-3 bg-white z-10 w-full">
              <p className="text-sm text-gray-dark">
                <strong>
                  [{cartItemsQuantity.toString().padStart(2, '0')}]
                </strong>{' '}
                {cartItemsQuantity > 1 ? 'itens' : 'item'} adicionados ao
                carrinho
              </p>
            </div>

            <ProductList
              items={productList}
              loading={loading}
              handleRemoveItem={handleRemoveProductFromCart}
              closeMiniCart={closeMiniCart}
              configurableThumbnailSource={configurableThumbnailSource}
            />
          </div>

          <div className={`mt-auto w-full shadow-up`}>
            <div className="py-4 px-6 flex justify-between bg-primary-lightest items-center">
              <span className="font-bold text-primary-medium text-sm">
                <FormattedMessage
                  id={'miniCart.subtotal'}
                  defaultMessage="Subtotal"
                />
              </span>

              <strong className="text-xl text-primary-medium">
                {subTotal}
              </strong>
            </div>

            <div className="px-6 py-6 grid gap-y-3 max-h-38">
              <Button
                type="button"
                onClick={handleEditCart}
                disabled={loading || isCartEmpty}
                className="font-bold w-full bg-primary-medium sm:text-white-lighest sm:text-sm"
              >
                <FormattedMessage
                  id={'miniCart.checkout'}
                  defaultMessage={'CHECKOUT'}
                />
              </Button>

              <Button
                type="button"
                onClick={() => closeMiniCart()}
                disabled={loading || isCartEmpty}
                className="font-bold w-full sm:border-2 sm:border-primary-medium sm:text-primary-medium sm:text-sm"
                variant="secondary"
              >
                Continuar comprando
              </Button>
            </div>
          </div>
        </div>
      )}
    </Modal>
  );
};
