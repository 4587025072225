import { useCallback, useMemo, useState } from 'react';
import { isEmpty } from 'lodash';

import Link from 'next/link';
import { useQuery } from '@apollo/client';
import { Modal } from '@common';
import { useAuth, useRecurrence } from '@hooks';

import logoImg from '@assets/logo.png';
import ArrowBack from '@assets/icons/arrow-point-left.svg';
import WhatsappMenuIcon from '@assets/icons/whatsapp_menu_icon.svg';
import ExitMenuIcon from '@assets/icons/exit_menu_icon.svg';
import PhoneMenuIcon from '@assets/icons/phone_menu_icon.svg';
import ArrowNext from '@assets/icons/arrow-right.svg';
import CloseIcon from '@assets/icons/x.svg';
import ShoppingBagIcon from '@assets/icons/shopping_bag-menu.svg';
import CalendarIcon from '@assets/icons/calendar-menu.svg';
import UserIcon from '@assets/icons/user-menu.svg';
import LocationPinIcon from '@assets/icons/location-menu.svg';
import { CategoryListProps, CategoryProps, GET_MEGA_MENU } from '../Megamenu';
import { useRouter } from 'next/navigation';
import Image from 'next/image';

interface NavigationContextProps {
  category: CategoryProps;
  setNavigationContext(category: CategoryProps): void;
  handleToggle?: () => void;
}

interface NavigationMenuProps {
  isVisible: boolean;
  handleToggle(): void;
}

interface NavigationItem {
  href: string;
  name: string;
  icon?: any;
  color?: string;
  height?: number;
  width?: number;
  prefetch?: boolean;
}

const formatLink = (linkCategory, linkLandingPageOnCategory) => {
  if (linkLandingPageOnCategory)
    return `/${linkLandingPageOnCategory.replace(/^\/+/, '')}/l`;
  return `/${linkCategory}/c`;
};

const NavigationLink = ({
  category,
  setNavigationContext,
  handleToggle
}: NavigationContextProps) => {
  const { push } = useRouter();
  const hasChildren = !isEmpty(category?.children);

  const handleClick = () => {
    if (hasChildren) {
      return setNavigationContext(category);
    }

    push(formatLink(category.url_path, category.category_link_url));
    handleToggle && handleToggle();
  };

  return (
    <li className="my-2">
      <button
        onClick={handleClick}
        className="py-4 w-full flex items-center justify-between border-b-2 border-gray-lightest"
      >
        <p>{category.name}</p>
        {hasChildren && (
          <ArrowNext width={12} height={12} className="text-primary-medium" />
        )}
      </button>
    </li>
  );
};

const POLICIES_PAGES: NavigationItem[] = [
  {
    href: '/politica-de-privacidade',
    name: 'Políticas de Privacidade'
  },
  {
    href: '/politica-de-cookies',
    name: 'Políticas de Cookies'
  }
];

const PURCHASE_BY: NavigationItem[] = [
  {
    href: 'https://wa.me/551130958482?text=Ol%C3%A1,%20como%20posso%20comprar%20pelo%20WhatsApp?',
    name: 'Compras por Whatsapp',
    icon: WhatsappMenuIcon,
    color: 'text-success-medium',
    width: 24,
    height: 24
  },
  {
    href: 'tel:+551130958482',
    name: 'Compras por telefone',
    icon: PhoneMenuIcon,
    width: 24,
    height: 24
  }
];

const ACCOUNT_ITEMS: NavigationItem[] = [
  {
    href: '/account-information',
    name: 'Minha Conta',
    icon: UserIcon,
    height: 24,
    width: 24
  },
  {
    href: '/orders',
    name: 'Meus Pedidos',
    color: 'text-white text-secondary-medium',
    icon: ShoppingBagIcon,
    height: 24,
    width: 24
  },
  {
    href: '/signature',
    name: 'Minhas Assinaturas',
    icon: CalendarIcon,
    height: 24,
    width: 24
  },

  {
    href: '/addresses',
    name: 'Meus Endereços',
    icon: LocationPinIcon,
    height: 24,
    width: 24
  }
];

const NavigationList = ({
  items,
  onClick
}: {
  onClick?(): void;
  items: NavigationItem[];
}) => {
  if (items?.length) {
    return (
      <ul className="mt-2 flex flex-col">
        {items.map(item => (
          <li key={item.href} className=" border-b-2 border-gray-lightest">
            <Link
              prefetch={item.prefetch || false}
              href={item.href}
              onClick={onClick}
              className="py-4 flex items-center"
            >
              {item.icon && (
                <item.icon
                  height={item.height}
                  width={item.width}
                  className={`self-cente mr-4 ${
                    item.color || 'text-primary-medium'
                  }`}
                />
              )}
              {item.name}
            </Link>
          </li>
        ))}
      </ul>
    );
  }
  return null;
};

export const NavigationMenuMobile = ({
  isVisible,
  handleToggle
}: NavigationMenuProps) => {
  const {
    recurrenceConfig: { enabled }
  } = useRecurrence();

  const { push } = useRouter();

  const { data } = useQuery(GET_MEGA_MENU);
  const { user, handleSignOut } = useAuth();
  const [navigationContext, setNavigationContext] = useState<CategoryListProps>(
    {} as CategoryListProps
  );

  const onLogout = () => {
    handleSignOut();
    handleToggle();
  };

  const categoryList = useMemo(() => {
    const categories = data?.categoryList[0] as CategoryListProps;

    return (
      categories?.children.filter(category => !!category.include_in_menu) || []
    );
  }, [data]);

  const handleViewAll = useCallback(() => {
    if (navigationContext?.url_path || navigationContext?.category_link_url) {
      push(
        formatLink(
          navigationContext.url_path,
          navigationContext.category_link_url
        )
      );
      handleToggle();
    }
  }, [
    handleToggle,
    navigationContext.category_link_url,
    navigationContext.url_path,
    push
  ]);

  return (
    <Modal
      className="max-w-[360px] pb-4 overscroll-contain gtm-menu"
      isVisible={isVisible}
      handleToggle={handleToggle}
      scrollContent
    >
      <div>
        <div className="flex p-6 justify-between">
          <div className="max-w-[320px]">
            <Image src={logoImg} alt="Mundo Danone Logo" />
          </div>
          <button type="button" onClick={handleToggle}>
            <CloseIcon width={24} height={24} className="text-primary-medium" />
          </button>
        </div>

        {!isEmpty(navigationContext) ? (
          <section className="w-full flex flex-col">
            <div className="flex justify-between pr-4">
              <h1
                onClick={() => setNavigationContext({} as CategoryListProps)}
                className="flex items-center text-primary-medium font-bold px-4"
              >
                <ArrowBack
                  aria-hidden="true"
                  height={18}
                  width={18}
                  className="mr-2"
                />{' '}
                {navigationContext.name}
              </h1>
              <span
                onClick={handleViewAll}
                className="text-primary-medium font-bold underline"
              >
                Ver todos
              </span>
            </div>

            <ul className="w-full px-6">
              {navigationContext?.children.map(category => (
                <NavigationLink
                  key={category.id}
                  category={category}
                  setNavigationContext={setNavigationContext}
                  handleToggle={handleToggle}
                />
              ))}
            </ul>
          </section>
        ) : (
          <section className="w-full flex flex-col px-4">
            {!isEmpty(user) ? (
              <div className="flex justify-between text-sm">
                <p>Olá! {user.firstname}</p>

                <Link
                  className="text-primary-medium font-bold underline"
                  href="/account-information"
                >
                  Minha conta
                </Link>
              </div>
            ) : (
              <div className="flex flex-col text-sm">
                <p className="mb-2">Bem-vindo!</p>

                <div className="flex flex-col">
                  <Link
                    onClick={handleToggle}
                    className="p-3 text-center font-bold text-white uppercase border-[2px] border-primary-medium bg-primary-medium rounded-lg"
                    href="/sign-in"
                  >
                    Faça o Login
                  </Link>

                  <Link
                    href="/sign-up"
                    type="button"
                    onClick={handleToggle}
                    className="p-3 text-center font-bold mt-2 border-[2px] border-primary-medium text-primary-medium uppercase rounded-lg"
                  >
                    Cadastre-se
                  </Link>
                </div>
              </div>
            )}

            <ul className="mt-4">
              {categoryList.map(category => (
                <NavigationLink
                  key={category.id}
                  category={category}
                  setNavigationContext={setNavigationContext}
                  handleToggle={handleToggle}
                />
              ))}
            </ul>

            {!isEmpty(user) && (
              <div className="flex flex-col">
                <NavigationList
                  items={
                    enabled
                      ? ACCOUNT_ITEMS
                      : ACCOUNT_ITEMS.filter(item => item.href !== '/signature')
                  }
                  onClick={handleToggle}
                />
              </div>
            )}

            <NavigationList items={PURCHASE_BY} onClick={handleToggle} />

            <NavigationList items={POLICIES_PAGES} onClick={handleToggle} />

            {!isEmpty(user) && (
              <button
                onClick={onLogout}
                className="flex  items-center w-full py-4 text-center"
              >
                <ExitMenuIcon className="text-secondary-medium" />
                <span className=" ml-4 text-error-medium ">Sair</span>
              </button>
            )}
          </section>
        )}
      </div>
    </Modal>
  );
};
