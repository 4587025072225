'use client';

import classNames from 'classnames';
import { HTMLAttributes, ReactElement, ReactNode, memo } from 'react';
import { FloatingButtons } from '../FloatingButtons';
import { Footer, Header } from '@common';

import { usePathname } from 'next/navigation';

interface LayoutProps extends HTMLAttributes<HTMLDivElement> {
  children: ReactNode;
}

export const Layout = memo(({ children, ...props }: LayoutProps) => {
  const pathname = usePathname();

  return (
    <>
      <Header />
      <main
        className={classNames(
          'w-full ',
          pathname == '/'
            ? 'dark:bg-gradient-to-b dark:from-black dark:via-black dark:to-[#063F96] dark:border-0 md:py-3'
            : '',
          props?.className
        )}
        {...props}
      >
        <div className="w-full lg:max-w-wrapper mx-auto">
          {children}

          <FloatingButtons />
        </div>
      </main>

      <Footer />
    </>
  );
});

Layout.displayName = 'Layout';

export const getLayout = (page: ReactElement) => {
  return <Layout>{page}</Layout>;
};
