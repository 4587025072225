import { GetSingleProductDetailsQuery } from '@generated/graphql';
import {
  createContext,
  Dispatch,
  ReactNode,
  SetStateAction,
  useContext,
  useState
} from 'react';
import {
  isProductConfigurable,
  useProductFullDetail
} from 'src/hooks/useProductFullDetail';

export type SingleProduct =
  GetSingleProductDetailsQuery['products']['items'][0] & {
    url_suffix?: string;
    variants?: any;
  };

type ConfigurableOptions = {
  __typename: string;
  attribute_code: string;
  attribute_id: string;
  id: number;
  label: string;
  values: {
    __typename: string;
    default_label: string;
    label: string;
    store_label: string;
    use_default_value: boolean;
    value_index: number;
    swatch_data: null;
  }[];
}[];

interface State<T> {
  current: T;
  set: Dispatch<SetStateAction<T>>;
}

export type ProductFromContext = SingleProduct & Record<string, any>;

interface IProductContext {
  product: ProductFromContext;
  isConfigurable: boolean;
  configurableOptions: ConfigurableOptions;
  handleSelectionChange: (optionId: string, selection: number) => void;
  state: {
    quantity: State<number>;
    reviews: State<typeof InitialReviewsState>;
  };
  optionSelections: Map<any, any>;
  isMissingOptions: boolean;
}

interface ProductPageProps {
  children: ReactNode;
  product: any;
}

export const InitialReviewsState = {
  review_count: 0,
  rating_average: 0,
  reviews: {
    items: [],
    page_info: {
      total_pages: 0
    }
  }
};

const ProductContext = createContext({} as IProductContext);

const useProductPage = () => {
  const context = useContext(ProductContext);
  return context;
};

const ProductPageProvider = ({ children, product }: ProductPageProps) => {
  const [quantity, setQuantity] = useState(1);
  const [reviews, setReviews] = useState(InitialReviewsState);
  const {
    productDetails,
    mediaGalleryEntries,
    handleSelectionChange,
    optionSelections,
    isMissingOptions
  } = useProductFullDetail({
    product
  });

  return (
    <ProductContext.Provider
      value={{
        product: {
          ...product,
          ...productDetails,
          media_gallery_entries: mediaGalleryEntries
        },
        isConfigurable: isProductConfigurable(product),
        configurableOptions: product?.configurable_options,
        handleSelectionChange,
        optionSelections,
        state: {
          quantity: {
            current: quantity,
            set: setQuantity
          },
          reviews: {
            current: reviews,
            set: setReviews
          }
        },
        isMissingOptions
      }}
    >
      {children}
    </ProductContext.Provider>
  );
};

export { ProductPageProvider, useProductPage };
